.carousel img {
    height: 325px !important;
    width: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
}

.carousel {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.carousel-button-left,
.carousel-button-right {
    all: unset;
    position: absolute;
    z-index: 1;
    height: 96%;
    width: 50%;
}

.carousel-button-left:hover,
.carousel-button-right:hover {
    cursor: pointer;
    background-color: rgba(5, 5, 5, 0.4)
}

.carousel-button-left {
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.carousel-button-right {
    right: 0;
    top: 0;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.carousel-indicator {
    width: 30px;
    height: 2px;
    display: inline-block;
    margin-left: 1px;
    margin-right: 1px;
    border-radius: 10px;
}

.carousel-img-html-attribution {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: x-small;
    color: white;
    background-color: rgba(24, 24, 24, 0.5);
}

.carousel-img-html-attribution a {
    color: #e1e1e1;
}

.carousel-img-html-attribution a:hover {
    color: rgb(14, 122, 254);
}


@media screen and (max-width: 600px) {
    .carousel-button-left:hover,
    .carousel-button-right:hover {
        background-color: rgba(0, 0, 0, 0);
    }
}

@media screen and (max-height: 750px) {
    .carousel img {
        height: 292.5px !important;
    }


}

@media screen and (max-height: 700px) {
    .carousel img {
        height: 270px !important;
    }

}