.hero-container,
.join-container,
.start-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.hero-container {
    margin-bottom: 5vh;
}

.home-body {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.home-body-overlay {
    
}

.hero-proposal,
.hero-question {
    width: fit-content;
    text-align: center;
    margin: 0;
}

.hero-proposal {
    font-size: 3.25rem;
}

.hero-question {
    font-size: 3rem;
}

.hero-italic {
    background: -webkit-linear-gradient(top left, #f950f1,  #ffdd1b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.join-start-container {
    display: flex;
    justify-content: center;
}

.join-start-box {
    width: fit-content;
    border: solid 2px #363636;
    border-radius: 30px;
    padding: 0px;
}

.join-box {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
}

.join-prompt {
    margin: 0px;
    margin-bottom: 20px;
    font-size: 1.3rem;
}

.join-input {
    all: unset;
    width: 250px;
    height: 30px;
    background-color: white;
    color: black;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
}

.join-input::placeholder {
    color: rgb(150, 150, 150);
}

.join-input:focus {
    outline: rgb(0, 122, 255) solid 2px;
}

.join-button-container {
    margin-top: 10px;
}

.home-button {
    all: unset;
    margin: 10px;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
    background: #242424;
    text-align: center;
    width: fit-content;
    border-radius: 20px;
    font-size: 1.05rem;
    font-weight: bold;
    color: white;
    background: -webkit-linear-gradient(#FD297B,  #FF655B);
}

.home-button:hover {
    cursor: pointer;
    background: -webkit-linear-gradient(#ff5496,  #fd8880);
}

.start-prompt {
    margin-bottom: 10px;
    margin-top: -20px;
    font-size: 1.3rem;
}

.error-message {
    color: rgb(221, 65, 56);
    width: 250px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px;
}

@media screen and (max-width: 1010px) {
    .hero-proposal {
        font-size: 5vw;
    }

    .hero-question {
        font-size: 4.5vw;
    }
}

@media screen and (max-width: 600px) {
    .hero-proposal,
    .hero-question {
        font-size: 2.25rem;
    }

    .hero-container {
        margin-bottom: 5vh;
    }
}
