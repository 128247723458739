.swipe-session-body {
    overflow: hidden;
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-container {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
}

.card {
    position: absolute;
}

.card:hover {
    cursor: pointer;
}

.card-box {
    width: 350px;
    height: 500px;
    background-color: #242424;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.card-image-container {
    height: 65%;
    width: 100%;
    background-color: #1c1c1c;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.card-description-container {
    height: 25%;
    width: 330px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    overflow-wrap: break-word;
    font-size: 1rem;
}

.card-name-container,
.card-info {
    height: 100%;
}

.card-name-container {
    margin-top: 40px;
    width: 200px;
}

.card-name {
    margin-bottom: 0px;
}

.card-info {
    width: 120px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.card-info-price {
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgb(85, 155, 85);
}

.card-info-rating {
    margin-top: 20px;
}

.card-info-stars {
    margin-top: 5px;
    margin-bottom: 0px;
    padding: 0px;
}

.card-info-number-ratings {
    margin-top: 5px;
    color: #929292;
}

.view-more-button {
    all: unset;
    height: fit-content;
    width: fit-content;
    padding: 5px;
    border: solid 1px #acacac;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
    user-select: auto;
    -moz-user-select: auto;
    -webkit-user-drag: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto;
}

.card-view-more-container {
    padding-left: 10px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-more-button:hover {
    cursor: pointer;
    opacity: 0.5;
}

.card-google-img {
    height: fit-content;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.end-text-box {
    width: 300px;
    height: 500px;
    text-align: center;
    position: relative;
}

@media screen and (max-height: 850px) {
    .card-container {
        margin-top: 10px;
    }

    .view-more-button:hover {
        opacity: 1;
    }

    .view-more-button:active {
        opacity: 0.5;
    }
}

@media screen and (max-height: 750px) {
    .card-box,
    .card-container,
    .end-text-box {
        margin-top: 0px;
        height: 450px;
    }

    .card-image-container {
        height: 65%;
    }

    .card-description-container {
        height: 25%;
    }

    .card-view-more-container {
        height: 10%;
    }
}

@media screen and (max-height: 700px) {

    .card-box,
    .card-container,
    .end-text-box {
        margin-top: 0px;
        height: 430px;
    }

    .card-image-container {
        height: 60%;
    }

    .card-description-container {
        height: 28%;
    }

    .card-view-more-container {
        height: 12%;
    }
}