.header {
    padding: 20px;
    padding-left: 30px;
}

.diner {
    width: fit-content;
    font-size: 2.5rem;
}

.diner:hover {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .diner {
        font-size: 1.75rem;
    }

    .header {
        padding-left: 20px;
    }
}