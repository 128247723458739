.info-modal {
    width: 50vw;
    padding: 20px;
}

.info-modal::-webkit-scrollbar {
    width: 15px;
}

/* Track */
.info-modal::-webkit-scrollbar-track {
    background-color: #242424;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

/* Handle */
.info-modal::-webkit-scrollbar-thumb {
    background-color: #646464;
    border-radius: 50px;
}

/* Handle on hover */
.info-modal::-webkit-scrollbar-thumb:hover {
    background-color: #525252;
}

.info-modal-restaurant-name {
    color: white;
}

.info-modal-address {
    font-weight: 400;
    color: #acacac;
}

.info-modal-rating-container {
    display: flex;
    align-items: baseline;
    margin-bottom: -15px;
}

.info-modal-rating-text {
    margin-top: 5px;
    color: #989898;
    font-weight: 400;
}

.info-modal-price {
    margin-top: 5px;
    margin-bottom: 10px;
    color: rgb(85, 155, 85)
}

.info-modal-hours-container {
    color: #E8E8E8;
}

.info-modal-hours-open-text {
    color: rgb(85, 155, 85)
}

.info-modal-rating {
    margin-top: 20px;
}

.info-modal-view-on-google-button {
    all: unset;
    margin-top: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #acacac;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.info-modal-view-on-google-button:hover {
    opacity: 0.5;
    cursor: pointer;
}

.info-modal-view-on-google-text {
    margin-right: 10px;
}

.info-modal-reviews-container {
    margin-top: 10px;
    color: #E8E8E8;
}

.info-modal-restaurant-description {
    color: #E8E8E8;
}

.info-modal-restaurant-description-container {
    margin-bottom: 10px;
}

.empty-reviews-message {
    color: #acacac;
}

.info-modal-single-review-container {
    margin-top: 8px;
    background-color: #363636;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    width: 95%;
}

.info-modal-single-review-container:hover {
    cursor: pointer;
    background-color: #484848;
}

.info-modal-review-photo {
    height: 50px;
    width: 50px;
}

.info-modal-review-left-side-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-right: 15px;
    padding: 5px;
}

.review-time-desc {
    color: #d1d1d1;
    margin-top: 2px;
    font-size: 0.9rem;
}

.review-rating-container {
    margin-top: 5px;
}

.review-right-side-container {
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.google-img {
    margin-right: 20px;
}

@media screen and (max-width: 600px) {
    .google-img {
        margin-right: 0px;
    }

    .info-modal {
        width: 280px;
    }

    .info-modal-single-review-container {
        width: 260px;
    }

    .info-modal-single-review-container {
        display: block;
    }
}

