.match-modal {
    width: 50vw;
    padding: 30px;
}

.match-header {
    font-size: 40px;
    background: -webkit-linear-gradient(top left, #fc468c, #fd9e97);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.match-description {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #acacac;
}

.match-image-container {
    margin: 15px;
    height: 300px;
    width: 100%;
}

.match-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 15px;
}

.match-info-rating {
    margin-top: 5px;
    color: #989898;
    font-weight: 400;
    margin-right: -5px;
}

.match-info-number-ratings {
    color: #989898;
    font-weight: 400;
    margin-left: -7px;
}

.match-info-price-level {
    color: rgb(85, 155, 85);
    margin-left: 15px;
}

.view-website-button {
    all: unset;
    display: flex;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #acacac;
    border-radius: 30px;
}

.view-website-button:hover {
    opacity: 0.5;
    cursor: pointer;
}

.view-website-text {
    margin-right: 5px;
}


@media screen and (max-width: 600px) {
    .match-header {
        font-size: 30px;
    }

    .match-description {
        font-size: 18px;
    }

    .match-modal {
        width: 300px;
        padding: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .match-image-container {
        height: 250px;
    }
}