.privacy-policy-body {
    overflow-y: scroll !important;
    height: 100vh;
}

.text-container {
    display: flex;
    justify-content: center;
}

.text-box {
    border: solid 2px #363636;
    border-radius: 30px;
    padding: 30px;
    width: 80vw;
    margin-bottom: 150px;
}

.text-container h2 {
    color: #e8e8e8;
}

.text-container h3 {
    color: #d9d9d9;
}

.text-container p {
    color: #bbbbbb;
}

.text-container hr {
    height: 2px;
    background-color: #363636;
    border: none;
    border-radius: 5px;
}

.text-container a {
    color: rgb(14, 122, 254);
}