.start-session-body {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.start-session-container {
    display: flex;
    justify-content: center;
}

.start-session-box {
    margin-top: 25px;
    border: solid 2px #363636;
    border-radius: 30px;
    width: 320px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

.address-form-container,
.radius-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.address-prompt,
.radius-prompt {
    font-size: 1.3rem;
    margin: 10px;
    margin-top: 25px;
    margin-bottom: 0px;
}

.address-input {
    all: unset;
    width: 250px;
    height: 30px;
    background-color: white;
    color: black;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.address-input:focus {
    outline: rgb(0, 122, 255) solid 2px;
}

.use-location-text {
    margin-top: 5px;
    color: #a4a4a4;
    margin-bottom: 15px;
}

.use-location-anchor {
    color: rgb(10, 132, 255);
    text-decoration: underline;
}

.use-location-anchor:hover {
    cursor: pointer;
    color: rgb(6, 96, 186);
}

.radius-prompt {
    margin-top: 25px;
    margin-bottom: 5px;
}

.radius-description {
    color: #a4a4a4;
    margin-bottom: 20px;
}

.radius-input-container {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0;
    border-radius: 5px;
}

.radius-input {
    all: unset;
    width: 30px;
    height: 30px;
    background-color: white;
    color: black;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: right;
    font-weight: bold;
}

.radius-input:focus {
    box-shadow: 0 -2px 0 rgb(0, 122, 255),
                -2px 0 0 rgb(0, 122, 255),
                0 2px 0 rgb(0, 122, 255);
}

.radius-label {
    background-color: #c7c7c7;
    color: black;
    height: 30px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    font-weight: bold;
    align-items: center;
}

.divider {
    margin: 25px;
    margin-bottom: 15px;
    width: 100%;
    background-color: rgb(157, 157, 157);
    height: 2px;
    border: none;
}

.error-message {
    color: rgb(221, 65, 56);
    width: fit-content;
    max-width: 250px;
    margin: 15px;
    margin-bottom: 0px;
}

.start-button {
    all: unset;
    margin: 10px;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
    background: -webkit-linear-gradient(#FD297B,  #FF655B);
    text-align: center;
    width: fit-content;
    border-radius: 30px;
    font-size: 1.05rem;
    font-weight: bold;
    color: white;
}

.start-button:hover {
    cursor: pointer;
    background: -webkit-linear-gradient(#ff5496,  #fd8880);
}

@media screen and (max-width: 600px) {
    .start-session-box {
        padding-left: 20px;
        padding-right: 20px;
    }
}